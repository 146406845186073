import React, { useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import InputWithLabel from '../texts/inputWithLabel';
import StandardButton from '../buttons/standardButton';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    content: {
        paddingLeft: 60,
        paddingRight: 60,
        paddingTop: 60,
        paddingBottom: 60,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    title: {
      width: '100%'
    },
    input: {
      marginTop: 16,
      width: 500
    },
    line: {
        backgroundColor: 'black',
        height: 1,
        width: '100%',
        marginTop: 32,
        marginBottom: 16
    },
    textarea: {
        width: '100%',
        height: 200,
        padding: 16,
        border: '1px solid #d3d3d3',
        backgroundColor: 'rgba(196, 196, 196, 0.22)',
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: 17,
        marginTop: 16
      }
};

const SendMailDialog = ({ isOpen, close, onSend }) => {
    const [to, setTo] = useState();
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    return (
    <Dialog open={isOpen} onBackdropClick={close} fullWidth={false} maxWidth="m">
        <DialogContent style={styles.container}>
            <div style={styles.content}>
                <div style={styles.textContainer}>
                    <h2 style={styles.title}>Send new message</h2>
                    <InputWithLabel
                        style={styles.input}
                        label="To"
                        type="text"
                        value={to}
                        setValue={setTo}
                    />
                    <InputWithLabel
                        style={styles.input}
                        label="Subject"
                        type="text"
                        value={subject}
                        setValue={setSubject}
                    />
                    <textarea
                        style={styles.textarea}
                        value={body}
                        onChange={event => setBody(event.target.value)}
                    />
                    <div style={styles.line} />
                    <StandardButton style={styles.sendButton} disabled={!to} text="Send" onClick={() => onSend(to, subject, body)} />
                </div>
            </div>
        </DialogContent>
    </Dialog>
)};

export default SendMailDialog;