import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    content: {
        paddingLeft: 60,
        paddingRight: 60,
        paddingTop: 60,
        paddingBottom: 60,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    imageContainer: {
        // width: 200,
        // height: 100,
        // borderRadius: 50,
        // border: '1px solid #d3d3d3',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 32
    },
    image: {
        width: 60,
        height: 60,
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    sender: {
        marginTop: 16,
        alignSelf: 'flex-start',
        fontWeight: 700,
        fontSize: 20
    },
    timestamp: {
        marginTop: 4,
        alignSelf: 'flex-start',
        fontWeight: 400,
        fontSize: 17
    },
    text: {
        marginTop: 32,
        alignSelf: 'flex-start',
        fontWeight: 400,
        fontSize: 17,
        width: '1000%'
    },
    line: {
        backgroundColor: 'black',
        height: 1,
        width: '100%',
        marginTop: 32,
        marginBottom: 16
    }
};

const MailDialog = ({ isOpen, close, image, sender, timestamp, text }) => (
    <Dialog open={isOpen} onBackdropClick={close} fullWidth={false} maxWidth="m">
        <DialogContent style={styles.container}>
            <div style={styles.content}>
                <div style={styles.imageContainer}>
                    <img style={styles.image} src={image} alt="profile" />
                </div>
                <div style={styles.textContainer}>
                    <p style={styles.sender}>{sender}</p>
                    <p style={styles.timestamp}>{timestamp?.toLocaleDateString()}</p>
                    <p style={styles.timestamp}>{text}</p>
                    <div style={styles.line} />
                </div>
            </div>
        </DialogContent>
    </Dialog>
);

export default MailDialog;