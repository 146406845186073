import React, { useContext, useState } from "react";
import Screen from '../components/views/screen';
import Navbar from '../components/navbars/insideNavbar';
import { TranslationContext } from "../translation/translationContext";
import { MessagesContext } from '../messages/messagesContext';
import { LIGHT_BLUE, PURPLE } from '../assets/colors';
import { insideNavbarHeight } from '../assets/dimensions';
import SoftButton from '../components/buttons/softButton';
import CheckBox from '../components/buttons/checkBox';
import MailDialog from '../components/dialogs/mailDialog';
import SendMailDialog from '../components/dialogs/sendMailDialog';
import logo from '../assets/images/logo.svg';

const styles = {
  container: {
    backgroundColor: LIGHT_BLUE,
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: insideNavbarHeight + 48,
    paddingBottom: 400
  },
  title: {
    color: PURPLE,
    fontSize: 40,
    fontWeight: 700,
    fontFamily: 'Roboto'
  },
  topContent: {
    display: 'flex',    
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 40,
    width: '50%',
  },
  filterButtons: {
    border: '1px solid #d3d3d3',
    display: 'flex',    
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 8,
    paddingRight: 8,
    backgroundColor: 'white'
  },
  filterButton: {
    fontFamily: 'Roboto',
    fontSize: 17,
    fontWeight: 400,
    marginRight: 8,
    marginLeft: 8
  },
  mailContainer: {
    width: '50%',
    border: '1px solid #d3d3d3',
    'min-height': 800,
    backgroundColor: '#F3F3F3',
  },
  mail: {
    border: '1px solid #d3d3d3',
    'border-bottom': '3px solid #d3d3d3',
    flex: 1,
    display: 'flex',    
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 32,
    backgroundColor: '#F3F3F3',
    paddingTop: 8,
    paddingBottom: 8
  },
  mailContent: {
    display: 'flex',    
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  checkBox: {
    marginRight: 32
  },
  senderAndTitleContainer: {
    display: 'flex',    
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  mailSender: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 700,
    marginRight: 24
  },
  mailTitle: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 400,
  },
  mailBody: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 400,
    marginLeft: 32
  },
  timestamp: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 400,
  },
  noMessages: {
    fontFamily: 'Roboto',
    fontSize: 24,
    textAlign: 'center',
    marginTop: 150
  }
};

const MessagesPage = () => {
  const { inbox, users, suppliers, sendMessage } = useContext(MessagesContext);
  const [chosenFilter, setChosenFilter] = useState();
  const [checkedMails, setCheckedMails] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [sendDialogOpen, setSendDialogOpen] = useState(false);
  const [openMail, setOpenMail] = useState();

  const { translate } = useContext(TranslationContext);

  // TODO: Fill out
  // const filters = [
  //     {title: translate('sent'), mails: sent, styleOverrides: { color: 'red' }},
  //     {title: translate('archive'), mails: []},
  //     {title: translate('drafts'), mails: drafts, styleOverrides: { color: 'red' }},
  //     {title: translate('flagged'), mails: []},
  // ];

  const messages = chosenFilter?.mails || inbox;

  const onPressSend = () => {
    setSendDialogOpen(true);
  };

  const onOpenMail = (mailId) => {
    const mail = messages.find(message => message.id === mailId);
    if (mail) {
        setOpenMail(mail);
        setDialogOpen(true);
    }
  };

  const onCloseMail = () => {
    setOpenMail();
    setDialogOpen(false);
  }

  const onPickFilter = (filter) => {
    const isChosen = filter.title === chosenFilter?.title;
    const newFilter = !isChosen && filter;
    setChosenFilter(newFilter);
    setCheckedMails([]);
    setOpenMail();
  };

  const onCheckMail = (id) => {
    const alreadyChecked = checkedMails.includes(id);
    setCheckedMails(prev => alreadyChecked ? prev.filter(i => i !== id) : [...prev, id]);
  };

  return (
    <Screen style={styles.container}>
      <Navbar currentTab={'/member'} />
      <MailDialog
        isOpen={dialogOpen}
        close={onCloseMail}
        image={logo} // TODO: Fetch from sender?
        sender={openMail?.sender ?? openMail?.recipient}
        timestamp={openMail?.timestamp}
        text={openMail?.text}
      />
      <SendMailDialog
        isOpen={sendDialogOpen}
        close={() => setSendDialogOpen(false)}
        onSend={(to, subject, body) => console.log({ to, subject, body })}
      />
      <div style={styles.topContent}>
        <h1 style={styles.title}>{translate('inboxTitle')}</h1>
        <SoftButton text={translate('sendNewMessage')} onClick={onPressSend} />
        {/* <div style={styles.filterButtons}>
            {filters.map(filter => (
                <p style={{...styles.filterButton, color: filter.title === chosenFilter?.title ? 'red' : 'black'}} onClick={() => onPickFilter(filter)}>
                    {filter.title}
                </p>
            ))}
        </div> */}
      </div>
      <div style={styles.mailContainer}>
          {messages.map(({ id, subject, body, from, sentAt }) => (
              <div style={styles.mail}>
                  <CheckBox style={styles.checkBox} isChecked={checkedMails.includes(id)} onCheck={() => onCheckMail(id)} />
                  <div style={styles.mailContent} onClick={() => onOpenMail(id)}>
                    <div style={styles.senderAndTitleContainer}>
                        <p style={{...styles.mailSender, ...chosenFilter?.styleOverrides}}>{from}</p>
                        <p style={styles.mailTitle}>{subject}</p>
                    </div>
                    <p style={styles.mailBody}>{body}</p>
                    <p style={styles.timestamp}>{sentAt}</p>
                  </div>
              </div>
          ))}
          {!messages.length && (
            <p style={styles.noMessages}>{translate('noMessages')}</p>
          )}
      </div> 
    </Screen>
  )
}

export default MessagesPage;
